/* stylelint-disable */

.select2-container--papa {
    font-family: $font-stack--extra;
    display: block;
    width: 100%;
    border-color: transparent;

    &.select2 {
        position: relative;
        background-color: #f8f8f8;
        border-radius: 16px;
        padding: 18px 40px 12px 20px;
        width: 100% !important;

        .is-white-select & {
            background-color: #ffffff;
        }

        &::after {
            position: absolute;
            content: '';
            top: 46%;
            right: 20px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $color-emerald;
        }

        &.select2-container--open {
            &::after {
                transform: rotate(180deg);
            }
        }

        &.select2-container--below.select2-container--open {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        &.select2-container--above.select2-container--open {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        .select2-selection__placeholder {
            font-weight: $font--normal;
            color: $color-nobel;

            .field.has-error  & {
                color: #e42313;
            }
        }

        .select2-selection__rendered {
            font-weight: $font--normal;
        }
    }

    .select2-results__option {
        padding: 12px 20px;
        background-color: #f8f8f8;
        transition: background-color 0.2s ease-out;

        .is-white-select & {
            background-color: #ffffff;
        }

        &:hover {
            background-color: rgba(235, 235, 235, 1);
        }
    }

    .select2-dropdown {
        border: none;
        font-family: $font-stack--extra;
        font-size: 16px;
        line-height: 20px;
        font-weight: $font--normal;
        color: $color--text;
        overflow-x: hidden;
        max-height: 300px;
        box-shadow: 0 4px 5px -2px #00000014;
    }

    .select2-dropdown.select2-dropdown--below {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .select2-dropdown.select2-dropdown--above {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .select2-selection__choice__remove {
        display: none;
    }

    .select2-selection.select2-selection--multiple {
        display: flex;
    }

    .select2-search__field {
        font-family: $font-stack--extra;
        background-color: #f8f8f8;
        caret-color: $color-emerald;

        &::placeholder {
            color: #bfb7b6;
        }
    }

    .select2-selection__choice__display {
        display: inline-flex;
        margin-top: 4px;
    }
}

.field.has-error .select .select2-container--papa.select2 {
    background-color: #ffc2bd;
    color: #e42313;
    border: 1px solid #e42313;

    &::after {
        border-top-color: #e42313;
    }

    .select2-search--inline .select2-search__field {
        background-color: #ffc2bd;
        color: #e42313;

        &::placeholder {
            color: #e42313;
        }
    }
}

