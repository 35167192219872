// Font families
// @type List
$font-stack--base: 'Gotham Pro', sans-serif !default;
$font-stack--extra: 'Roboto', sans-serif !default;

// Font-sizes
$fs: 16px;
//line-height
$lh: 24px;

// Font weights
// @type Length
$font--ultra-light: 100 !default;
$font--light: 200 !default; // or thin
$font--book: 300 !default;
$font--demi: 350 !default;
$font--normal: 400 !default; // or regular
$font--medium: 500 !default;
$font--semi-bold: 600 !default; // or demi-bold
$font--bold: 700 !default;
$font--heavy: 800 !default; // or black/extra-bold
$font--fat: 900 !default; // or extra-black/poster/ultra-black

// Colors
// @type Color
$color-white: #fff !default;
$color-emerald: #50a684;
$color-alabaster: #f8f8f8;
$color-athens-gray: #f4f4f8;
$color-almost-black: #141111;
$color-nobel: #bfb7b6;
$color-light-cyan: #e1faf4;
$color-gallery: #ededed;
$color-pearl-beige: #70544f;
$color-tomato-red: #e42313;

// Text colors
$color--text: $color-pearl-beige;

// Default line-height
// @type Length
$lh--base: calc($lh / $fs); // 22/18

// Grid width
// @type Length
$width--min: 375px !default;
$md: 640px;
$old-fashion-lg: 1229px;
$lg: 1440px;

// Transitions
// @type Property Values
$transition--default: all 0.2s ease-out;
